import { graphql } from "gatsby";
import React, { useState } from "react";

import Activities from "../components/Activities/Activities";
import Layout from "../components/common/layout/layout";
// import { Contact } from "../components/Forms/Contact/Contact";
// import withForm from "../components/Forms/withForm";
import Hero from "../components/Hero/Hero";
import Map from "../components/Map/Map";
import ModalForm from "../components/Modal/ModalForm";
import SEO from "../components/SEO/SEO";
import PcisBanners from "../PCIS/components/PCIsBanner/PcisBanners";

const PageActividadesComplementarias = ({ data }) => {
  // const ContactWithForm = withForm(Contact);
  const [has_modal, setHasModal] = useState(false);
  const [from, setFrom] = useState("Actividades complementarias");

  return (
    <>
      {has_modal && <ModalForm title={"Solicita información"} is_active={setHasModal} form={Contact} from={from} />}
      <SEO
        title={"Actividades complementarias y extraescolares"}
        canonical={"actividades-complementarias"}
        description={""}
        robots={"index, follow"}
      />
      <Layout>
        <Hero title={"Actividades Complementarias y Extraescolares"} />
        <main>
          <section className={"container"}>
            <div style={{ maxWidth: "800px" }}>
              <p className={"h2"}>
                Las actividades complementarias y extraescolares están centradas en el desarrollo
                integral de los estudiantes y sirven para profundizar en el currículo con nuestro alumnado, tanto a nivel técnico como cultural.
              </p>
              <p>
                En el siguiente documento aparece el listado tipo de actividades de un curso escolar normalizado,
                susceptible de modificación según las circunstancias.
              </p>
            </div>
          </section>
          <section>
            <div className={"container"}>
              <h2>Información sobre nuestras actividades</h2>
              <Activities activities={data.allSanityActivity.nodes} select={setHasModal} from={setFrom} />
            </div>
          </section>
          <PcisBanners />
          <Map>
            <h2> ¿Necesitas ponerte en contacto con el departamento PCI IES Ramón y Cajal?</h2>
            <p>
              Si tienes más dudas, envía un correo electrónico a la siguiente dirección{" "}
              <a href={"mailto:pci.ryc.zgz@gmail.com"} target={"_blank"} rel={"noopener noreferrer"}>
                {" "}
                pci.ryc.zgz@gmail.com{" "}
              </a>{" "}
              o rellena el siguiente formulario de información
            </p>
            {/*<ContactWithForm />*/}
          </Map>
        </main>
      </Layout>
    </>
  );
};

export default PageActividadesComplementarias;

export const query = graphql`
  query Activities {
    allSanityActivity(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        name
        _rawDescription
        photo {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
